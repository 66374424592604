/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://rgufrwcauj.execute-api.us-east-2.amazonaws.com/sisecdev",
            "region": "us-east-2"
        },
        {
            "name": "getStudies",
            "endpoint": "https://h0vezu0ceh.execute-api.us-east-2.amazonaws.com/sisecdev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://24oj3bhnrva6bakjnatlw2wme4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:43c80df8-9ab6-47f9-93ba-691d0266195d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_oHYDrSfDa",
    "aws_user_pools_web_client_id": "5vao3fe9ntr5p9l937fi7baefd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sisechelpfiles111140-sisecdev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
